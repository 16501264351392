/* eslint-env browser */
/* global PARTYKIT_HOST */

import "./styles.css";

import PartySocket from "partysocket";

let pingInterval;

const output = document.getElementById("app"),
      gameboard = document.getElementById("gameboard");

function add({ message, messageType, playerId }) {
    const div = document.createElement('div');
    const who = messageType === 'system' ? '' : playerId.split('-')[0];
    div.textContent = `${who}> ${message}`;
    document.getElementById('chat-output').appendChild(div);
}

if (!window.location.hash) {
    window.location.hash = `#${Math.random().toString(36).substr(2, 9)}`;
}

const conn = new PartySocket({
    host: PARTYKIT_HOST,
    room: window.location.hash.replace('#', ''),
});

let isDrawing = false;
let lastX = 0;
let lastY = 0;
let drawingHistory = [];
let clientId = Math.random().toString(36).substr(2, 9);

const playerCanvases = new Map();
const playerCursors = new Map();

const colorPicker = document.getElementById('colorPicker');
const brushSize = document.getElementById('brushSize');
const clearButton = document.getElementById('clearButton');

let dimensions = {
    width: 512,
    height: 680,
};

/*
const onResize = () => {
    dimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
    };
}
window.addEventListener('resize', onResize);
onResize();
*/

const baseLayer = document.getElementById('base');

let canvas,
    ctx;

function createPlayerCanvas(playerId) {
    const playerCanvas = document.createElement("canvas");
    playerCanvas.width = '512';
    playerCanvas.height = '680';
    playerCanvas.getContext("2d").drawImage(baseLayer, 0, 0);
    gameboard.appendChild(playerCanvas);
    playerCanvases.set(playerId, playerCanvas);
    return playerCanvas;
}

function sendDrawingData(x, y, color, size, isNewStroke) {
    const data = {
        type: 'draw',
        x,
        y,
        color,
        size,
        isNewStroke,
        clientId,
        timestamp: Date.now()
    };
    conn.send(JSON.stringify(data));
    drawingHistory.push(data);
}

// Handle received drawing data
conn.addEventListener('message', (event) => {
    const message = JSON.parse(event.data);
    console.log(message);

    if (message.type === 'message') {
        add(message);
    }
                      
    if (message.type === 'join') {
        //createPlayerCanvas(message.playerId);
    }
    if (message.type === 'start') {
        startGame();
    }
    if (message.type === 'stop') {
        stopGame();
        canvas.style.display = 'none';

        let replay = message.state;
        for (let pid of Object.keys(replay)) {
            let state = replay[pid];
            canvas = playerCanvases.get(pid) || createPlayerCanvas(pid);
            console.log(pid, state, canvas);
            ctx = canvas.getContext('2d');
            state.forEach(item => drawLine(item.x, item.y, item.color, item.size, item.isNewStroke));
        }
        exportCanvases(message.exporter);
    }
    if (message.type === 'evaluationResults') {
        displayResults(message.results);
    }
    if (message.type === 'cursor') {
        console.log(message);
        playerCursors.set(message.playerId, message.position);
        let cursor = document.getElementById(`cursor-${message.playerId}`);
        if (!cursor) {
            cursor = document.createElement('div');
            cursor.id = `cursor-${message.playerId}`;
            cursor.style.position = "absolute";
            cursor.style.width = "15px";
            cursor.innerHTML = `
<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 837.513 837.513" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M771.863,2.64l-754.6,246.3c-22.8,7.4-23.1,39.5-0.4,47.4l233.5,81.1c20.899,7.3,27,33.9,11.399,49.601l-241.7,241.6 c-19.5,19.5-19.5,51.2,0,70.7l78.1,78.1c19.5,19.5,51.2,19.5,70.7,0l241.7-241.7c15.7-15.699,42.3-9.5,49.6,11.4l81.1,233.5 c7.9,22.6,40,22.3,47.4-0.4l246.3-754.6C847.664,26.74,810.764-10.061,771.863,2.64z"></path> </g> </g></svg>
`;
            document.body.append(cursor);;
        }
        const style = {};
        
        style.top = message.position.y + canvas.offsetTop;
        style.left = message.position.x + canvas.offsetLeft;
        cursor.style.top = `${style.top}px`;
        cursor.style.left = `${style.left}px`;
    }
    return;
    if (message.type === 'draw') {
        drawLine(message.playerId, message.x, message.y, message.color, message.size, message.isNewStroke);
    } else if (message.type === 'clear') {
        clearCanvas();
        drawingHistory = [];
    } else if (message.type === 'fullState') {
        clearCanvas();
        drawingHistory = message.state;
        drawingHistory.forEach(item => drawLine(item.x, item.y, item.color, item.size, item.isNewStroke));
    }
});

function startDrawing(e) {
    isDrawing = true;
    [lastX, lastY] = [e.offsetX, e.offsetY];
    sendDrawingData(lastX, lastY, colorPicker.value, brushSize.value, true);
}

function draw(e) {
    if (!isDrawing) return;
    const x = e.offsetX;
    const y = e.offsetY;
    const color = colorPicker.value;
    const size = brushSize.value;

    drawLine(x, y, color, size, false);
    sendDrawingData(x, y, color, size, false);
}

function drawLine(x, y, color, size, isNewStroke) {
    ctx.strokeStyle = color;
    ctx.lineWidth = size;
    ctx.lineCap = 'round';

    if (isNewStroke) {
        ctx.beginPath();
        ctx.moveTo(x, y);
    } else {
        ctx.beginPath();
        ctx.moveTo(lastX, lastY);
        ctx.lineTo(x, y);
        ctx.stroke();
    }

    [lastX, lastY] = [x, y];
}

function stopDrawing() {
    isDrawing = false;
}

function clearCanvas() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
}

conn.addEventListener("open", function () {
    canvas = createPlayerCanvas(clientId);
    ctx = canvas.getContext('2d');
});

function startGame() {
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseout', stopDrawing);
    canvas.addEventListener('mousemove', trackCursor);
    
    console.log('game is starting!');
}

function stopGame() {
    canvas.removeEventListener('mousedown', startDrawing);
    canvas.removeEventListener('mousemove', draw);
    canvas.removeEventListener('mouseup', stopDrawing);
    canvas.removeEventListener('mouseout', stopDrawing);
    canvas.removeEventListener('mousemove', trackCursor);
    stopDrawing();

    console.log('game is over!');
}

async function exportCanvases(exporter) {
    if (conn.id === exporter) {
        const pngData = {};
        for (const [playerId, canvas] of playerCanvases) {
            // Create a temporary canvas for scaling
            const tempCanvas = document.createElement('canvas');
            const tempCtx = tempCanvas.getContext('2d');
            
            // Set the desired height and calculate the proportional width
            const scaledHeight = 100;
            const scaledWidth = (canvas.width / canvas.height) * scaledHeight;
            
            tempCanvas.width = scaledWidth;
            tempCanvas.height = scaledHeight;
            
            // Draw the original canvas onto the scaled canvas
            tempCtx.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);
            
            // Get the scaled image data
            pngData[playerId] = tempCanvas.toDataURL('image/png');
        }
        conn.send(JSON.stringify({
            type: 'exportPNGs',
            pngData: pngData
        }));
    }
}

function displayResults(results) {
    const resultsContainer = document.createElement('div');
    resultsContainer.id = 'results-container';
    resultsContainer.style.marginTop = '20px';

    for (const [playerId, result] of Object.entries(results)) {
        const resultDiv = document.createElement('div');
        resultDiv.innerHTML = `
            <h3>Player ${playerId}</h3>
            <p>Description: ${result.description}</p>
            <p>Critique: ${result.critique}</p>
            <p>Rating: ${result.rating}/10</p>
        `;
        resultsContainer.appendChild(resultDiv);
    }

    document.getElementById('chat-output').appendChild(resultsContainer);
}

document.getElementById('chat-input').addEventListener('change', e => {
    if (conn) {
        conn.send(JSON.stringify({
            type: 'message',
            message: e.target.value,
        }));
    }
    e.target.value = '';
});

const trackCursor = e => {
    if (!conn) return;

    if (!dimensions.width || !dimensions.height) return;
    const position = {
        x: e.offsetX, //clientX / dimensions.width,
        y: e.offsetY, //clientY / dimensions.height,
    };
    conn.send(JSON.stringify({
        type: 'cursor',        
        position
    }))
}
